<template>
  <el-main class="Shorten">
    <el-row>
      <el-col :span="16" :xs="24" :xl="12">
        <el-card>
          <div class="alert">
            使用此服务默认您同意<a href="#" target="_blank" style="color: #333;">《链接管理规范》</a>
          </div>
          <el-input class="shorten-input" v-model="shortForms.link" placeholder="请输入需要缩短的链接">
            <template v-if="!showAdvanced" #append>
              <el-button @click="submit">缩短</el-button>
            </template>
          </el-input>
          <el-radio-group v-model="shortForms.domain">
            <el-radio v-for="(item, index) in radioLabel" :key="index" :label="item.domain"></el-radio>
          </el-radio-group>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :xs="24" :xl="12" class="advanced">
        <span @click="showAdvanced = !showAdvanced">
          {{ showAdvanced ? '隐藏高级选项' : '显示高级选项' }}<em :class="showAdvanced ? 'el-icon-arrow-up':'el-icon-arrow-down'"></em>
        </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :xs="24" :xl="12">
        <el-collapse-transition>
          <el-card v-show="showAdvanced">
            <el-form ref="shortForm" :model="shortForms" label-width="80px" size="mini">
              <el-form-item label="昵称备注">
                <el-input placeholder="请输入昵称备注" v-model="shortForms.name"></el-input>
              </el-form-item>
              <el-form-item label="访问密码">
                <el-input placeholder="请输入访问密码，不传则不设置" show-password v-model="shortForms.password"></el-input>
              </el-form-item>
              <el-form-item label="有效时间">
                <el-input placeholder="请输入有效时间(天数), 默认永久" v-model="shortForms.day">
                  <template #append>天</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">立即缩短</el-button>
                <el-button @click="showAdvanced = !showAdvanced">隐藏高级选项</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-collapse-transition>
      </el-col>
    </el-row>
    <!-- Dialog弹出层 -->
    <!-- 生成后对话框 -->
    <teleport to='body'>
      <div class="short-dialog">
        <el-dialog
          center
          custom-class="short-dialog"
          top="30vh"
          :width="responsively ? 280 : 480"
          destroy-on-close
          title="生成信息"
          :close-on-click-modal="false"
          @close="closeShortDialog"
          v-model="shortDialog"
        >
          <el-image :src="dialogItem.qrcode"></el-image>
          <p>{{ dialogItem.short }}<em @click="copy" class="icon el-icon-copy-document"></em></p>
        </el-dialog>
      </div>
    </teleport>
  </el-main>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'

interface IDialogItem {
  short: string,
  qrcode: string,
}

interface IShortForms {
  [key: string]: string | boolean | number,
  link: string,
  domain: string,
  password: string,
  day: string,
  name: string
}

interface IRadioLabel {
  id: number,
  domain: string,
  default: string
}

interface IState {
  shortDialog: boolean,
  radioLabel: Array<IRadioLabel>,
  dialogItem: IDialogItem,
  shortForms: IShortForms
}

export default defineComponent({
  name: 'Shorten',
  setup () {
    const state = reactive<IState>({
      shortDialog: false,
      radioLabel: [],
      dialogItem: {
        short: '',
        qrcode: ''
      },
      shortForms: {
        link: '',
        domain: '',
        password: '',
        day: '',
        name: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const { proxy }: any = getCurrentInstance()
    const showAdvanced = ref<boolean>(false)
    const link = ref<string>('')
    const userInfo = computed(() => store.getters.GET_USER_INFO)
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/domain/lists')
        .then((res: IResponse) => {
          const lists = res.data.lists
          lists.forEach((item: IRadioLabel) => {
            if (item.default === '1') {
              state.shortForms.domain = item.domain
            }
          })
          state.radioLabel = lists
        })
    }
    getTableList()

    // 缩短链接
    const submit = (): void => {
      proxy.$axios.post('/link/generate', state.shortForms)
        .then((res: IResponse) => {
          if (res.code === 1) {
            // ElMessage.success({
            //   message: res.msg,
            //   type: 'success'
            // })
            state.dialogItem = res.data
            setTimeout(() => {
              state.shortDialog = true
            }, 100)
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
    }

    const closeShortDialog = (): void => {
      for (const key in state.shortForms) {
        state.shortForms[key] = ''
      }
    }

    const copy = (): void => {
      const input = document.createElement('input')
      input.value = state.dialogItem.short
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    return {
      link,
      userInfo,
      showAdvanced,
      submit,
      closeShortDialog,
      ...stateAsRefs,
      responsively,
      copy
    }
  }
})
</script>

<style lang="scss" scoped>
  .View {
    width: 100%;
    height: 100%;
  }
  .Shorten {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('/images/bg.jpg') center center / cover no-repeat;
  }

  .alert {
    color: #999;
    font-size: 14px;
    padding: 0 0 14px;
  }

  .el-row {
    width: 100%;
    align-items: center;
    justify-content: center;
    + .el-row {
      margin-top: 20px;
    }
  }

  .el-radio-group {
    margin: 20px 0 0;
  }

  .shorten-input {
    :deep .el-input-group__append {
      color: #FFF;
      background: #409EFF;
      border: 1px solid #409EFF;
      &:hover {
        background: #58abff;
      }
    }
  }

  .advanced {
    color: #FFF;
    font-size: 14px;
    text-align: center;
    span {
      cursor: pointer;
      letter-spacing: 2px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .short-dialog {
    .icon {
      cursor: pointer;
      margin-left: 4px;
    }
    :deep(.el-dialog__body) {
      text-align: center;
    }
  }
</style>
