
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'

interface IDialogItem {
  short: string,
  qrcode: string,
}

interface IShortForms {
  [key: string]: string | boolean | number,
  link: string,
  domain: string,
  password: string,
  day: string,
  name: string
}

interface IRadioLabel {
  id: number,
  domain: string,
  default: string
}

interface IState {
  shortDialog: boolean,
  radioLabel: Array<IRadioLabel>,
  dialogItem: IDialogItem,
  shortForms: IShortForms
}

export default defineComponent({
  name: 'Shorten',
  setup () {
    const state = reactive<IState>({
      shortDialog: false,
      radioLabel: [],
      dialogItem: {
        short: '',
        qrcode: ''
      },
      shortForms: {
        link: '',
        domain: '',
        password: '',
        day: '',
        name: ''
      }
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const { proxy }: any = getCurrentInstance()
    const showAdvanced = ref<boolean>(false)
    const link = ref<string>('')
    const userInfo = computed(() => store.getters.GET_USER_INFO)
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/domain/lists')
        .then((res: IResponse) => {
          const lists = res.data.lists
          lists.forEach((item: IRadioLabel) => {
            if (item.default === '1') {
              state.shortForms.domain = item.domain
            }
          })
          state.radioLabel = lists
        })
    }
    getTableList()

    // 缩短链接
    const submit = (): void => {
      proxy.$axios.post('/link/generate', state.shortForms)
        .then((res: IResponse) => {
          if (res.code === 1) {
            // ElMessage.success({
            //   message: res.msg,
            //   type: 'success'
            // })
            state.dialogItem = res.data
            setTimeout(() => {
              state.shortDialog = true
            }, 100)
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
    }

    const closeShortDialog = (): void => {
      for (const key in state.shortForms) {
        state.shortForms[key] = ''
      }
    }

    const copy = (): void => {
      const input = document.createElement('input')
      input.value = state.dialogItem.short
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    return {
      link,
      userInfo,
      showAdvanced,
      submit,
      closeShortDialog,
      ...stateAsRefs,
      responsively,
      copy
    }
  }
})
